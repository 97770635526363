<template>
  <q-page padding class="center">
    <h5>Temp holding</h5>
  </q-page>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>
<style lang="stylus">
.center
  text-align: center
</style>
