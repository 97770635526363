<template>
  <q-card
    v-ripple="isValid"
    class="cursor-pointer no-margin card-shadow"
    :class="classes"
    :disabled="!isValid"
    @click="isValid && $emit('selected', result)"
  >
    <div class="card-container">
      <div class="logo">
        <svg viewBox="0 0 63 18" width="63" height="18">
          <use :xlink:href="require('assets/citylink-logo.svg') + '#citylink-logo'" />
        </svg>
      </div>
      <div class="card-info-container">
        <div>
          <p class="text-subtitle2">
            Depart
          </p>
          <p><strong>{{ formatDate(result.depart_date) }} {{ formatTime(result.depart_time) }}</strong> - {{ result.origin_description }}</p>
        </div>
        <div>
          <p class="text-subtitle2">
            Arrive
          </p>
          <p><strong>{{ formatDate(result.arrive_date) }} {{ formatTime(result.arrive_time) }}</strong> - {{ result.dest_description }}</p>
        </div>
      </div>
      <div class="changes-price">
        <div>
          <div class="changes-duration">
            <p class="text-subtitle2">
              {{ numberOfChangesText }}
            </p>
            <p class="text-subtitle2">
              {{ formattedDuration }}
            </p>
          </div>
          <p class="text-subtitle1">
            Service(s) - {{ serviceNumbersText }}
          </p>
        </div>
        <p class="text-right">
          <strong>{{ totalPriceText }} {{ disabledReason ? disabledReason : fareType }}</strong>
        </p>
      </div>
    </div>
    <q-separator />
    <q-expansion-item
      label="Journey Details"
      header-class="text-subtitle1 text-weight-bold"
      @click.stop
    >
      <div class="expansion-content-container">
        <div v-for="journey in result.journeys" :key="journey.journey_id">
          <p class="q-pb-sm">
            <strong>Service {{ journey.service }}</strong>
          </p>
          <div class="service-content">
            <p>From</p>
            <div>
              <p class="text-subtitle1 text-weight-bold">
                {{ journey.origin_description }}
              </p>
              <p>{{ formatDate(journey.depart_date) }} {{ formatTime(journey.depart_time) }}</p>
            </div>
            <p>To</p>
            <div>
              <p class="text-subtitle1 text-weight-bold">
                {{ journey.dest_description }}
              </p>
              <p>{{ formatDate(journey.arrive_date) }} {{ formatTime(journey.arrive_time) }}</p>
            </div>
          </div>
        </div>
      </div>
    </q-expansion-item>
  </q-card>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
const { toHugeDate, toCivilDateTime } = date

export default {
  props: ['result', 'isReturn'],
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    numberOfChangesText () {
      const numberOfChanges = this.result.interchange_stops.length
      return `${numberOfChanges} Change${numberOfChanges > 1 || numberOfChanges === 0 ? 's' : ''}`
    },
    formattedDuration () {
      const [hours, minutes] = this.result.duration.split(':')
      return `${Number(hours).toString()}h ${minutes}m`
    },
    serviceNumbersText () {
      const serviceNumbersList = this.result.journeys.map(journey => journey.service)
      return serviceNumbersList.join(', ')
    },
    totalPriceText () {
      if (!this.result.fare_line_list || this.result.fare_line_list?.[0].error) return ''

      const allPrices = this.result.fare_line_list.map(fare => fare.price_without_ins)
      const sum = allPrices.reduce((sum, value) => sum + value, 0)
      return `Total price £${sum.toFixed(2)}`
    },
    fareType () {
      const fareType = this.result.fare_line_list?.[0].fare_type

      if (!fareType) return ''

      const formattedFareType = `${fareType[0].toUpperCase()}${fareType.toLowerCase().substr(1)}`
      return `(${formattedFareType})`
    },
    isSelected () {
      if (this.isReturn) {
        return this.stash.selected?.return?.pax_journey_id === this.result.pax_journey_id
      } else {
        return this.stash.selected?.depart?.pax_journey_id === this.result.pax_journey_id
      }
    },
    classes () {
      return [this.isSelected ?? 'selected bg-primary-selected shadow-3']
    },
    isValid () {
      return this.computeValidity().isValid
    },
    disabledReason () {
      return this.computeValidity().reason
    }
  },
  methods: {
    formatDate (date) {
      return toHugeDate(date)
    },
    formatTime (time) {
      const splitTime = time.split(':')
      return `${splitTime[0]}:${splitTime[1]}`
    },
    computeValidity () {
      if (!this.result.available) {
        return { isValid: false, reason: this.result.status ? `${this.result.status[0].toUpperCase()}${this.result.status.toLowerCase().substr(1)}` : 'Not available' }
      }

      if (this.result.fare_line_list?.[0]?.error) {
        return { isValid: false, reason: this.result.fare_line_list[0].error }
      }

      if (this.isReturn) {
        const scheduledOutboundArrival = toCivilDateTime(`${this.stash.selected.depart.arrive_date} ${this.stash.selected.depart.arrive_time}`)
        const scheduledDeparture = toCivilDateTime(`${this.result.depart_date} ${this.result.depart_time}`)

        if (scheduledDeparture < scheduledOutboundArrival) {
          return { isValid: false, reason: 'Departure before outbound arrival' }
        }
      }

      return { isValid: true, reason: null }
    }
  }
}
</script>

<style lang="stylus" scoped>

// Because we remove  in the q-card class in mobile view :shrug:
.card-shadow
  -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12)
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12)

.changes-duration
 display flex
 gap 1rem

.expansion-content-container
  display flex
  flex-direction column
  gap 1rem
  padding: 1rem

.service-content
  display grid
  grid-template-columns 0.15fr 1fr
  gap 1rem

.logo
  grid-row: 1 / span 2

.changes-price
  grid-column 2 / span 1
  display flex
  justify-content space-between
  grid-template-columns 1fr 1fr
  align-items flex-end
  width 100%

.no-margin
  margin 0 !important

.card-container
  display grid
  grid-template-columns auto 1fr
  align-items center
  padding 1rem
  gap: 1.5rem
  @media (max-width 768px)
    display flex
    flex-direction column

.card-info-container
  display grid
  grid-template-columns 1fr
  gap: 1rem

</style>
