import http from './http'

export function stops (query) {
  return http.get(`/citylink/stop-lookup?query=${query}`)
}

export function search (data) {
  return http.post(`/citylink/search`, data)
}

export function createBasket (token, data) {
  return http.post(`/citylink/${token}/create-basket`, data)
}

export function addLuggage (token, data) {
  return http.post(`/citylink/${token}/add-luggage`, data)
}

export function fetchDeliveryOptions (token) {
  return http.get(`/citylink/${token}/delivery-options`)
}

export function setDeliveryOption (token, data) {
  return http.post(`/citylink/${token}/delivery-options`, data)
}

export function passengerInfo (token, data) {
  return http.post(`/citylink/${token}/passenger-info`, data)
}

export function preCheckout (token, data) {
  return http.post(`/citylink/${token}/pre-checkout`, data)
}
