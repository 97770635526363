<template>
  <q-page padding class="passenger-info-container">
    <q-card>
      <q-card-section class="bg-primary">
        Lead Passenger Information
      </q-card-section>
      <q-card-section>
        <h6>
          These details will be used for the booking confirmation and for delivery.
        </h6>
      </q-card-section>
      <q-separator />
      <q-card-section class="column q-gutter-md">
        <q-input
          v-model="passengerInfo.first_name"
          :error="$v.passengerInfo.first_name.$invalid && $v.passengerInfo.first_name.$dirty"
          label="Passenger First Name *"
          @blur="$v.passengerInfo.first_name.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.first_name.required && $v.passengerInfo.first_name.$dirty">This field is required</span>
            <span v-if="!$v.passengerInfo.first_name.isNameValid && $v.passengerInfo.first_name.$dirty">Invalid name</span>
          </template>
        </q-input>
        <q-input
          v-model="passengerInfo.last_name"
          :error="$v.passengerInfo.last_name.$invalid && $v.passengerInfo.last_name.$dirty"
          label="Passenger Surname *"
          @blur="$v.passengerInfo.last_name.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.last_name.required && $v.passengerInfo.last_name.$dirty">This field is required</span>
            <span v-if="!$v.passengerInfo.last_name.isNameValid && $v.passengerInfo.last_name.$dirty">Invalid name</span>
          </template>
        </q-input>
        <h6>Ticket Delivery</h6>
        <div class="delivery-options">
          <q-radio v-for="deliveryOption in deliveryOptions" :key="deliveryOption.delivery_method_id"
                   v-model="passengerInfo.delivery_method" :val="deliveryOption.delivery_method_id" :label="deliveryOption.short_description"
          />
        </div>
        <q-input
          v-if="passengerInfo.delivery_method === '1'"
          v-model="passengerInfo.email_address"
          :error="$v.passengerInfo.email_address.$invalid && $v.passengerInfo.email_address.$dirty"
          label="Email Address *"
          @blur="$v.passengerInfo.email_address.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.email_address.required && $v.passengerInfo.email_address.$dirty">This field is required</span>
            <span v-if="!$v.passengerInfo.email_address.email && $v.passengerInfo.email_address.$dirty">Must be a valid email address</span>
          </template>
        </q-input>
        <p
          v-if="passengerInfo.delivery_method === '2'"
          class="text-subtitle2"
        >
          There will be an additional cost of £{{ stash.basket.tickets.length.toFixed(2) }} for this delivery method
        </p>
        <q-input
          v-if="passengerInfo.delivery_method === '2'"
          v-model="passengerInfo.phone_number"
          :error="$v.passengerInfo.phone_number.$invalid && $v.passengerInfo.phone_number.$dirty"
          label="Mobile Number *"
          @blur="$v.passengerInfo.phone_number.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.phone_number.required && $v.passengerInfo.phone_number.$dirty">This field is required</span>
            <span v-if="(!$v.passengerInfo.phone_number.numeric || !$v.passengerInfo.phone_number.maxLength || !$v.passengerInfo.phone_number.minLength) && $v.passengerInfo.phone_number.$dirty">Must be a valid mobile number</span>
          </template>
        </q-input>
      </q-card-section>
    </q-card>

    <q-card>
      <q-card-section class="bg-primary">
        Billing Address
      </q-card-section>
      <q-card-section class="column q-gutter-md">
        <q-input
          v-model="passengerInfo.address1"
          :error="$v.passengerInfo.address1.$invalid && $v.passengerInfo.address1.$dirty"
          label="Address Line 1 *"
          @blur="$v.passengerInfo.address1.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.address1.required && $v.passengerInfo.address1.$dirty">This field is required</span>
          </template>
        </q-input>
        <q-input
          v-model="passengerInfo.address2"
          label="Address Line 2"
        />
        <q-input
          v-model="passengerInfo.address3"
          label="Address Line 3"
        />
        <q-input
          v-model="passengerInfo.town"
          :error="$v.passengerInfo.town.$invalid && $v.passengerInfo.town.$dirty"
          label="Town / City *"
          @blur="$v.passengerInfo.town.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.town.required && $v.passengerInfo.town.$dirty">This field is required</span>
          </template>
        </q-input>
        <q-input
          v-model="passengerInfo.county"
          label="Region"
        />
        <q-input
          v-model="passengerInfo.postcode"
          :error="$v.passengerInfo.postcode.$invalid && $v.passengerInfo.postcode.$dirty"
          label="Postcode *"
          @blur="$v.passengerInfo.postcode.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.postcode.required && $v.passengerInfo.postcode.$dirty">This field is required</span>
          </template>
        </q-input>
        <q-select
          v-model="passengerInfo.country"
          :error="$v.passengerInfo.country.$invalid && $v.passengerInfo.country.$dirty"
          :options="reorderedCountries"
          label="Country *"
          @blur="$v.passengerInfo.country.$touch"
        >
          <template v-slot:error>
            <span v-if="!$v.passengerInfo.country.required && $v.passengerInfo.country.$dirty">This field is required</span>
          </template>
        </q-select>
      </q-card-section>
    </q-card>

    <concession-passenger-card
      v-for="passenger in necPassengers"
      :key="passenger.fare_line_id"
      :passenger="passenger"
      :model="concessionPassengers.find(concession => concession.trans_seq_num === passenger.trans_seq_num)"
      @validation-status="handleConcessionValidation"
    />

    <p class="text-h6">
      Journey Price: {{ totalJourneyPrice }}
    </p>
    <m-cta-button
      label="Submit"
      width="inherit"
      :disabled="!isFormValid"
      @ctaClick="submit"
    />
  </q-page>
</template>

<script>
import store from 'store'
import { required, email, numeric, maxLength, minLength, requiredIf, helpers } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import {
  fetchDeliveryOptions,
  setDeliveryOption,
  passengerInfo,
  preCheckout
} from 'api/coach'

import { countries } from 'utils/quasar-countries'
import { MCtaButton } from 'components/'
import concessionPassengerCard from './concession-passenger-card'
import { handleErrors } from 'utils/utils'

const isNameValid = helpers.regex('isNameValid', /^[a-z- ]*$/i)

export default {
  components: { MCtaButton, concessionPassengerCard },
  data () {
    return {
      deliveryOptions: [],
      passengerInfo: {
        first_name: '',
        last_name: '',
        // 1 is e-ticket, 2 is text ticket, default to neither
        delivery_method: null,
        email_address: null,
        phone_number: null,
        address1: null,
        address2: null,
        address3: null,
        town: null,
        county: null,
        postcode: null,
        country: null
      },
      concessionPassengers: [],
      concessionsValidation: []
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    reorderedCountries () {
      let reorderedCountries = [...countries]
      const index = reorderedCountries.findIndex(country => country.value === 'GB')
      if (index > -1) {
        reorderedCountries.splice(index, 1)
        reorderedCountries.unshift({
          label: 'United Kingdom',
          value: 'GB'
        })
      }
      return reorderedCountries
    },
    totalJourneyPrice () {
      let basketTotal = this.stash.basket?.basket_total
      if (this.passengerInfo.delivery_method === '2') {
        basketTotal += 2
      }
      return `£${basketTotal.toFixed(2)}`
    },
    necPassengers () {
      return this.stash.basket.tickets.map(ticket => {
        const reservation = this.stash.basket.basket_items.reservations[0].selected_fare_lines.find(fareLine => fareLine.fare_line_id === ticket.fare_line_id)
        if (reservation.fare_class !== 'ADULT' && reservation.fare_class !== 'CHILD') {
          return {
            ...ticket,
            fare_class: reservation.fare_class
          }
        }
      }).filter(passenger => !!passenger)
    },
    isFormValid () {
      if (this.concessionsValidation.length) {
        return !this.$v.$invalid && this.concessionsValidation.every(concession => concession.valid)
      } else {
        return !this.$v.$invalid
      }
    }
  },
  validations: {
    passengerInfo: {
      first_name: { required, isNameValid },
      last_name: { required, isNameValid },
      delivery_method: { required },
      email_address: {
      // Email is required if delivery_method is 1
        required: requiredIf(function () {
          return this.passengerInfo.delivery_method === '1'
        }),
        email
      },
      phone_number: {
      // Phone number is required if delivery_method is 2
        required: requiredIf(function () {
          return this.passengerInfo.delivery_method === '2'
        }),
        numeric,
        maxLength: maxLength(11),
        minLength: minLength(11)
      },
      address1: { required },
      town: { required },
      postcode: { required },
      country: { required }
    }
  },
  watch: {
    'passengerInfo.delivery_method' (newValue, oldValue) {
      // Clear email_address if the delivery method is Eticket
      if (newValue !== '1') {
        this.passengerInfo.email_address = ''
      }
      // Clear phone_number if the delivery method is not TxtTicket
      if (newValue !== '2') {
        this.passengerInfo.phone_number = ''
      }
    }
  },
  created () {
    this.concessionPassengers = this.necPassengers.map(passenger => {
      return {
        trans_seq_num: passenger.trans_seq_num,
        first_name: '',
        last_name: '',
        card_no: ''
      }
    })
    this.concessionsValidation = this.necPassengers.map(passenger => {
      return {
        trans_seq_num: passenger.trans_seq_num,
        valid: false
      }
    })
  },
  async beforeRouteEnter (to, from, next) {
    loading.start({
      message: 'Fetching delivery options...',
      partnerSlug: store.getters.partner.slug,
      spinnerColor: store.getters.partner.hex
    })
    try {
      const { data } = await fetchDeliveryOptions(store.getters['ondemand/stash'].results.token)
      next(vm => {
        vm.deliveryOptions = data.delivery_options
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },

  methods: {
    async submit () {
      loading.start({
        message: 'Loading summary...',
        partnerSlug: store.getters.partner.slug,
        spinnerColor: store.getters.partner.hex
      })

      try {
        await setDeliveryOption(this.stash.results.token, {
          product_id: this.passengerInfo.delivery_method
        })

        const { first_name, last_name, address1, address2, address3, town, county, postcode, country, phone_number, email_address } = this.passengerInfo

        const { data: basket } = await passengerInfo(this.stash.results.token, {
          passenger_info: {
            title: '',
            first_name,
            last_name,
            address1,
            address2: address2 ?? '',
            address3: address3 ?? '',
            town,
            county: county ?? '',
            postcode,
            country: country.value,
            daytime_phone_number: phone_number ?? '',
            email_address: email_address ?? '',
            terms_consent: 'N',
            email_consent: 'N',
            phone_consent: 'N',
            post_consent: 'N',
            pax_references: this.concessionPassengers
          }
        })

        const { data: updatedBasket } = await preCheckout(this.stash.results.token)

        // Use pax info from passenger info response as precheckout does not return email?
        const combinedBasket = {
          ...basket,
          ...updatedBasket,
          basket_items: {
            ...updatedBasket.basket_items,
            pax_info: basket.basket_items.pax_info
          }
        }

        await this.$store.dispatch('ondemand/stash', { basket: combinedBasket })
        this.$router.push({ name: 'ondemand-coach-summary' })
      } catch (err) {
        handleErrors(err)
        console.warn(err)
      } finally {
        loading.stop()
      }
    },
    handleConcessionValidation (data) {
      const { trans_seq_num, validationStatus } = data
      const validationIndex = this.concessionsValidation.findIndex(concession => concession.trans_seq_num === trans_seq_num)

      const updatedValidation = [...this.concessionsValidation]
      updatedValidation[validationIndex] = { trans_seq_num, valid: validationStatus }

      this.concessionsValidation = updatedValidation
    }
  }
}
</script>
<style lang="stylus">
  .passenger-info-container
    display flex
    flex-direction column
    gap 1rem
    .q-card
      -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12)
      box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12)
    @media (max-width 768px)
      padding 1rem

.delivery-options
  display flex
  gap 2rem
</style>
