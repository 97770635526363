<template>
  <q-page padding class="column-container summary-container">
    <q-card>
      <q-card-section class="bg-primary">
        Booking Summary
      </q-card-section>
      <q-card-section>
        <h6>
          Please check all details are correct and complete payment below.
        </h6>
      </q-card-section>
      <q-separator />
      <q-card-section>
        {{ passengerList }}
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="column-container">
          <summary-journey-card
            direction="Outbound"
            :date="stash.selected.depart.depart_date"
            :departure-time="stash.selected.depart.depart_time"
            :arrival-time="stash.selected.depart.arrive_time"
            :origin="stash.selected.depart.origin_description"
            :destination="stash.selected.depart.dest_description"
            :duration="stash.selected.depart.duration"
            :changes="stash.selected.depart.interchange_stops"
          />
          <q-separator inset />
          <summary-journey-card
            v-if="stash.selected.return"
            direction="Return"
            :date="stash.selected.return.depart_date"
            :departure-time="stash.selected.return.depart_time"
            :arrival-time="stash.selected.return.arrive_time"
            :origin="stash.selected.return.origin_description"
            :destination="stash.selected.return.dest_description"
            :duration="stash.selected.return.duration"
            :changes="stash.selected.return.interchange_stops"
          />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="column-container">
          <h6>
            Lead Passenger Details
          </h6>
          <div class="info-field">
            <p class="text-grey-8">
              Passenger Name
            </p>
            <p>{{ stash.basket.basket_items.pax_info.first_name }} {{ stash.basket.basket_items.pax_info.last_name }}</p>
          </div>
          <div class="info-field">
            <p class="text-grey-8">
              {{ emailOrMobileTitle }}
            </p>
            <p>{{ emailOrMobileData }}</p>
          </div>
          <div class="info-field">
            <p class="text-grey-8">
              Billing Address
            </p>
            <p>{{ billingAddress }}</p>
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="column-container">
          <h6>
            To Pay
          </h6>
          <div class="info-field">
            <p class="text-grey-8">
              Ticket price -
            </p>
            <p>£{{ totalTicketPrice }}</p>
          </div>
          <div v-if="smsFee" class="info-field">
            <p class="text-grey-8">
              SMS fee -
            </p>
            <p>£{{ smsFee }}</p>
          </div>
          <div v-if="luggageFee" class="info-field">
            <p class="text-grey-8">
              Luggage fee -
            </p>
            <p>£{{ luggageFee.toFixed(2) }}</p>
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="info-field items-center">
          <p class="text-grey-8 text-bold">
            Total price -
          </p>
          <h6>
            £{{ stash.basket.basket_total.toFixed(2) }}
          </h6>
        </div>
      </q-card-section>
    </q-card>
    <m-attach-to-booking-request
      class="width-600"
      :suggested="stash.basket.tickets[0].description ? stash.basket.tickets[0].description : 'Journey name'"
      :username="this.$store.getters.userLookup.value"
      :journey="journey"
      :show-travel-type="travelPolicy && travelPolicy.coach.enabled === true"
      @change="(val) => $store.dispatch('ondemand/journey', val)"
      @reasonChange="(val) => reason_for = val"
      @labelChange="(val) => labels = val"
      @travelTypeChange="(val) => travelType = val"
    />
    <payment-buttons
      class="width-600"
      :total-amount="stash.basket.basket_total"
      :requester="this.$store.getters.userLookup"
      :traveller="this.$store.getters.userLookup"
      :journey="journey"
      :reason-for="reason_for"
      :labels="labels"
      :booking-token="stash.results.token"
      :travel-type="(travelPolicy && travelPolicy.coach.enabled === true) || !organisation ? travelType : 'Private'"
    />
    <m-terms />
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import SummaryJourneyCard from './summary-journey-card'
import { MAttachToBookingRequest, MTerms } from 'components/'
import paymentButtons from 'pages/payments/payment-method-buttons'

export default {
  components: {
    SummaryJourneyCard,
    MAttachToBookingRequest,
    paymentButtons,
    MTerms
  },
  data () {
    return {
      reason_for: null,
      labels: null,
      travelType: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      organisation: 'organisation'
    }),
    travelPolicy () {
      if (!this.organisation) return null
      if (this.organisation?.attributes?.travel_policy) {
        return this.organisation.attributes.travel_policy
      }
      return { coach: { enabled: true } }
    },
    passengerList () {
      const { adults, children, sixtyPlusNecHolders, underTwentyTwoNecHolders, volunteerNecHolders } = this.stash.formParams.passengers
      let passengerList = []
      if (adults) passengerList.push(this.$tc('num.adults', adults))
      if (children) passengerList.push(this.$tc('num.children', children))
      if (sixtyPlusNecHolders) passengerList.push(this.$tc('num.sixtyPlusNecHolders', sixtyPlusNecHolders))
      if (underTwentyTwoNecHolders) passengerList.push(this.$tc('num.underTwentyTwoNecHolders', underTwentyTwoNecHolders))
      if (volunteerNecHolders) passengerList.push(this.$tc('num.volunteerNecHolders', volunteerNecHolders))
      return passengerList.join(', ')
    },
    emailOrMobileTitle () {
      if (this.stash.basket.basket_items.pax_info.email_address) return 'Email Address'
      if (this.stash.basket.basket_items.pax_info.daytime_phone_number) return 'Mobile Number'
      return null
    },
    emailOrMobileData () {
      const email = this.stash.basket.basket_items.pax_info.email_address
      const mobile = this.stash.basket.basket_items.pax_info.daytime_phone_number
      return email || (mobile || null)
    },
    billingAddress () {
      const { address1, address2, town, postcode, country } = this.stash.basket.basket_items.pax_info
      return [address1, address2, town, postcode, country].filter(segment => !!segment).join(', ')
    },
    totalTicketPrice () {
      return this.stash.basket.tickets.reduce((sum, ticket) => sum + ticket.price, 0).toFixed(2)
    },
    smsFee () {
      return this.stash.basket.basket_items.products.find(product => product.product_id === '2')?.price.toFixed(2)
    },
    luggageFee () {
      const luggageSsr = this.stash.basket.basket_items.ssr?.find(ssr => ssr.ssr_type === 'LUGGAGE')
      if (luggageSsr) return luggageSsr.total_price
      return null
    }
  }
}
</script>
<style lang="stylus">
  .column-container
    display flex
    flex-direction column
    gap 1rem

  .summary-container
    @media (max-width 768px)
      padding 1rem
    .q-card
      -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12) !important
      box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12) !important

  .info-field
    display flex
    gap 1rem
</style>
