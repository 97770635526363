<template>
  <q-page v-if="!hasResults" padding class="no-results">
    <p class="text-h6">
      No coaches found for your given search
    </p>
  </q-page>
  <q-page v-else padding class="page-container">
    <div class="results-header-container">
      <p class="text-h6">
        <strong>{{ isOutbound ? 'Outbound' : 'Return' }} Journey</strong>
      </p>
      <p>{{ routeTitle }}</p>
      <depart-return
        :value="isReturn ? `${stash.params.return_date} ${stash.params.return_time}` : `${stash.params.departure_date} ${stash.params.departure_time}`"
        :custom-label="isReturn ? 'Time of return' : 'Departure time'"
        class="time-transport"
        :time-options="timeOptions"
        :date-options="isReturn ? returnDateOptions : dateOptions"
        :show-return="true"
        @datesave="setNewDate"
        @timesave="setNewTime"
      />
      <p>
        {{ passengers }}
      </p>
    </div>
    <div>
      <m-cta-button
        :label="disableEarlierOptions ? 'Earlier return times unavailable' : 'View Earlier Options'"
        :outlined="true"
        :disabled="disableEarlierOptions"
        @ctaClick="fetchEarlierOptions"
      />
    </div>
    <div
      v-if="isOutbound"
      class="results-container"
    >
      <result-card
        v-for="result in stash.results.outward.slice(0, 6)"
        :key="result.pax_journey_id"
        :result="result"
        :is-return="false"
        @selected="onOutboundSelect"
      />
    </div>
    <div
      v-else-if="isReturn"
      class="results-container"
    >
      <result-card
        v-for="result in stash.results.return.slice(0, 6)"
        :key="result.pax_journey_id"
        :result="result"
        :is-return="true"
        @selected="onReturnSelect"
      />
    </div>
    <div>
      <m-cta-button
        label="View Later Options"
        :outlined="true"
        @ctaClick="fetchLaterOptions"
      />
    </div>
    <div class="q-pb-md">
      <m-cta-button
        label="Continue"
        :disabled="disabled"
        @ctaClick="submit"
      />
    </div>
  </q-page>
</template>

<script>
import store from 'store'
import loading from 'utils/loading'
import i18n from 'i18n'
import { search, createBasket, addLuggage } from 'api/coach'
import { handleErrors } from 'utils/utils'
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
import ResultCard from './result-card.vue'
import { MCtaButton } from 'components/'
import departReturn from 'components/MLeaveNow/depart-return.vue'
const { toHugeDate, addToDate, subtractFromDate, toCivilDateTime, toCivilDate, newDate, toCivilTime } = date

export default {
  components: {
    ResultCard, MCtaButton, departReturn
  },
  props: {
    isOutbound: {
      type: Boolean,
      required: true
    },
    isReturn: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      disableEarlierOptions: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    hasResults () {
      return this.stash.results?.outward.length > 0
    },
    routeTitle () {
      const firstResult = this.stash.results?.outward[0]
      const journeyType = `${this.stash.params.journey_type_id[0].toUpperCase()}${this.stash.params.journey_type_id.substr(1)}`
      return `${firstResult.origin_description} > ${firstResult.dest_description} - ${journeyType}`
    },
    departureDate () {
      return toHugeDate(this.stash.params.departure_date)
    },
    departureTime () {
      const splitTime = this.stash.params.departure_time.split(':')
      return `${splitTime[0]}:${splitTime[1]}`
    },
    returnDate () {
      return toHugeDate(this.stash.params.return_date)
    },
    returnTime () {
      const splitTime = this.stash.params.return_time.split(':')
      return `${splitTime[0]}:${splitTime[1]}`
    },
    passengers () {
      const fareIds = this.stash.params.fare_ids

      const fareIdMap = {
        ADULT: 'Adult',
        CHILD: 'Child',
        CONCESSION: '60+/Disabled NEC Holder',
        U22: 'Under 22 NEC Holder',
        YPEC: 'Volunteer NEC Holder'
      }

      const passengerList = fareIds.map(fare => {
        return `${fareIdMap[fare.fare_id]} x ${fare.count}`
      })

      return passengerList.join(', ')
    },
    disabled () {
      if (this.isOutbound) {
        if (this.stash.selected?.depart) return false
      }
      if (this.isReturn) {
        if (this.stash.selected?.return) return false
      }
      return true
    }
  },
  methods: {
    onOutboundSelect (journey) {
      const selected = this.stash.selected ?? {}
      selected.depart = journey
      store.dispatch('ondemand/stash', { selected })
    },
    onReturnSelect (journey) {
      const selected = this.stash.selected ?? {}
      selected.return = journey
      store.dispatch('ondemand/stash', { selected })
    },
    dateOptions (date) {
      return date >= toCivilDate(newDate()).replace(/-/g, '/') && date <= toCivilDate(addToDate(newDate(), { years: 2 })).replace(/-/g, '/')
    },
    returnDateOptions (date) {
      return date >= this.stash.params.departure_date.replace(/-/g, '/') && date <= toCivilDate(addToDate(this.stash.params.departure_date, { years: 2 })).replace(/-/g, '/')
    },
    timeOptions (hour, minutes) {
      // If outbound, allow all times
      if (this.isOutbound) return true
      // If return date is another day, allow all times
      if (this.stash.params.return_date > this.stash.params.departure_date) return true
      // If same day, only allow 15 minutes after departure time (can be adjusted)
      const [departureHour, departureMinutes] = this.stash.params.departure_time.split(':')
      if (hour < departureHour) return false
      if (minutes === null) return true
      if (hour === Number(departureHour)) {
        if (minutes < (Number(departureMinutes) + 15)) return false
      }
      return true
    },
    async submit () {
      if (this.stash.params.journey_type_id === 'single') {
        await this.createBasket()
      }
      if (this.stash.params.journey_type_id === 'return') {
        if (this.isReturn) {
          await this.createBasket()
        } else if (this.isOutbound) {
          this.$router.push({ name: 'ondemand-coach-return' })
        }
      }
    },
    async createBasket () {
      const token = this.stash.results.token
      const outwardJourneyId = this.stash.selected.depart.pax_journey_id
      const returnJourneyId = this.stash.selected.return?.pax_journey_id

      loading.start({
        message: 'Creating basket...',
        partnerSlug: store.getters.partner.slug,
        spinnerColor: store.getters.partner.hex
      })

      const luggage_count = this.stash.luggageParams?.reduce((count, luggage) => count + luggage.additionalLuggage, 0)

      try {
        let { data: basket } = await createBasket(token, {
          outward_journey_id: outwardJourneyId,
          ...(returnJourneyId && { return_journey_id: returnJourneyId })
        })

        if (luggage_count) {
          const { data } = await addLuggage(token, {
            luggage_count
          })
          basket = data
        }

        await this.$store.dispatch('ondemand/stash', { basket })
        this.$router.push({ name: 'ondemand-coach-passenger-info' })
      } catch (err) {
        handleErrors(err)
      } finally {
        loading.stop()
      }
    },
    fetchEarlierOptions () {
      const currentDepartOrReturn = this.isReturn ? `${this.stash.params.return_date} ${this.returnTime}` : `${this.stash.params.departure_date} ${this.departureTime}`
      let earlierDepartOrReturn = toCivilDateTime(subtractFromDate(currentDepartOrReturn, { hours: 3 }))
      if (this.isReturn) {
        // If new return search is going to be before depart date, update to depart date and disable option to view earlier options
        if (earlierDepartOrReturn <= toCivilDateTime(`${this.stash.params.departure_date} ${this.departureTime}`)) {
          earlierDepartOrReturn = toCivilDateTime(`${this.stash.params.departure_date} ${this.departureTime}`)
          this.disableEarlierOptions = true
        }
      }
      const [ newDate, newTime ] = earlierDepartOrReturn.split(' ')
      const updatedSearchParams = {
        ...this.stash.params,
        [this.isReturn ? 'return_date' : 'departure_date']: newDate,
        [this.isReturn ? 'return_time' : 'departure_time']: `${newTime}:00`
      }
      store.dispatch('ondemand/stash', { params: updatedSearchParams })
      this.search()
    },
    fetchLaterOptions () {
      const currentDepartOrReturn = this.isReturn ? `${this.stash.params.return_date} ${this.returnTime}` : `${this.stash.params.departure_date} ${this.departureTime}`
      let laterDepartOrReturn = toCivilDateTime(addToDate(currentDepartOrReturn, { hours: 3 }))
      if (this.isReturn) {
        this.disableEarlierOptions = false
      }
      const [ newDate, newTime ] = laterDepartOrReturn.split(' ')
      const updatedSearchParams = {
        ...this.stash.params,
        [this.isReturn ? 'return_date' : 'departure_date']: newDate,
        [this.isReturn ? 'return_time' : 'departure_time']: `${newTime}:00`
      }
      store.dispatch('ondemand/stash', { params: updatedSearchParams })
      this.search()
    },
    setNewDateTime (val) {
      const [date, time] = val.split(' ')

      // Determine if we're updating outbound or return date/time
      const dateField = this.isReturn ? 'return_date' : 'departure_date'
      const timeField = this.isReturn ? 'return_time' : 'departure_time'

      // Update the outbound or return date/time
      const updatedSearchParams = {
        ...this.stash.params,
        [dateField]: date,
        [timeField]: `${time}:00`
      }

      // Additional logic for when setting outbound date/time
      if (this.isOutbound) {
        // Check if the new outbound date/time is before the current return date/time
        const isOutboundAfterReturn = this.stash.params.return_date < date || (this.stash.params.return_date === date && this.stash.params.return_time <= time)

        if (isOutboundAfterReturn) {
          // Update return date/time to be after the new outbound date/time
          updatedSearchParams.return_date = date
          updatedSearchParams.return_time = `${toCivilTime(addToDate(time, { minutes: 15 }))}:00`
        }
      }
      store.dispatch('ondemand/stash', { params: updatedSearchParams })
    },
    setNewDate (val) {
      this.setNewDateTime(val)
    },
    setNewTime (val) {
      // Separate functions because we don't want to run the search when only the date has changed
      this.setNewDateTime(val)
      this.search()
    },
    async search () {
      loading.start({
        message: i18n.t('loading.checking.coaches'),
        partnerSlug: store.getters.partner.slug,
        spinnerColor: store.getters.partner.hex
      })
      try {
        const { data } = await search(this.stash.params)
        // Check if outbound and clear selected outbound
        if (this.isOutbound) store.dispatch('ondemand/stash', { selected: { depart: null } })

        // If return, and outbound is selected, find corresponding outbound from new search data via arrival/departure date/times
        if (this.isReturn) {
          const currentOutboundJourney = this.stash.selected.depart
          const outboundStringIdentifier = `${currentOutboundJourney.depart_date} ${currentOutboundJourney.depart_time} ${currentOutboundJourney.arrive_date} ${currentOutboundJourney.arrive_time}`

          const newOutboundJourney = data.outward.find(journey => {
            const newJourneyString = `${journey.depart_date} ${journey.depart_time} ${journey.arrive_date} ${journey.arrive_time}`
            return newJourneyString === outboundStringIdentifier
          })

          if (newOutboundJourney) store.dispatch('ondemand/stash', { selected: { depart: newOutboundJourney } })
          else {
            console.error('Failed to match outbound journey with identifier:', outboundStringIdentifier)
            throw new Error('Unable to update outbound journey')
          }
        }
        store.dispatch('ondemand/stash', { results: data })
      } catch (err) {
        handleErrors(err)
      } finally {
        loading.stop()
      }
    }
  },
  // TODO: Will probably need dynamic request based on single/return or multi journey due to different endpoints
  // To implement once backend flow has been done. Might even be a separate component
  async beforeRouteEnter (to, from, next) {
    const { params, results } = await store.getters['ondemand/stash']
    // Clear basket in case of navigating back
    store.dispatch('ondemand/stash', { basket: null })
    if (!results) {
      loading.start({
        message: i18n.t('loading.checking.coaches'),
        partnerSlug: store.getters.partner.slug,
        spinnerColor: store.getters.partner.hex
      })
      try {
        const { data } = await search(params)
        store.dispatch('ondemand/stash', { results: data })
        next()
      } catch (err) {
        handleErrors(err)
        next(false)
      } finally {
        loading.stop()
      }
    } else {
      next()
    }
  }
}
</script>
<style lang="stylus">

.page-container
  display: flex
  flex-direction: column
  gap: 2rem
  @media (max-width 768px)
   padding 2rem

.no-results
  display: flex
  justify-content: center
  padding-top: 7rem

.results-header-container
  display: grid
  gap: 1rem

.results-container
  display flex
  flex-direction column
  gap 2rem

.time-transport
  background-color white
  border none
  width 100%
  display grid
  grid-template-columns 20px auto auto 20px
  grid-column-gap 5px
  align-items center
  padding 10px 5px

</style>
