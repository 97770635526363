<template>
  <q-card>
    <q-card-section class="bg-primary">
      Concessions Ticket
    </q-card-section>
    <q-card-section>
      {{ passenger.description }}
    </q-card-section>
    <q-separator />
    <q-card-section>
      <q-input
        v-model="model.first_name"
        :error="$v.model.first_name.$invalid && $v.model.first_name.$dirty"
        label="First Name *"
        @blur="$v.model.first_name.$touch"
      >
        <template v-slot:error>
          <span v-if="!$v.model.first_name.required && $v.model.first_name.$dirty">This field is required</span>
          <span v-if="!$v.model.first_name.isNameValid && $v.model.first_name.$dirty">Invalid name</span>
        </template>
      </q-input>
      <q-input
        v-model="model.last_name"
        :error="$v.model.last_name.$invalid && $v.model.last_name.$dirty"
        label="Last Name *"
        @blur="$v.model.last_name.$touch"
      >
        <template v-slot:error>
          <span v-if="!$v.model.last_name.required && $v.model.last_name.$dirty" class="q-pr-sm">This field is required</span>
          <span v-if="!$v.model.last_name.isNameValid && $v.model.last_name.$dirty">Invalid name</span>
        </template>
      </q-input>
      <q-input
        v-model="model.card_no"
        :error="$v.model.card_no.$invalid && $v.model.card_no.$dirty"
        label="National Entitlement Card Number *"
        @blur="$v.model.card_no.$touch"
      >
        <template v-slot:error>
          <span v-if="!$v.model.card_no.required && $v.model.card_no.$dirty" class="q-pr-sm">This field is required.</span>
          <span v-if="!$v.model.card_no.numeric && $v.model.card_no.$dirty" class="q-pr-sm">Card number can only contain digits.</span>
          <span v-if="(!$v.model.card_no.minLength || !$v.model.card_no.maxLength) && $v.model.card_no.$dirty" class="q-pr-sm">Must be 16 Digits.</span>
          <span v-if="!$v.model.card_no.necNumberValidate && $v.model.card_no.$dirty">Please enter a valid NEC number.</span>
        </template>
      </q-input>
    </q-card-section>
  </q-card>
</template>

<script>
import { required, numeric, minLength, maxLength, helpers } from 'vuelidate/lib/validators'

const necNumberValidate = (value) => {
  return value.startsWith('630') || value.startsWith('633')
}

const isNameValid = helpers.regex('isNameValid', /^[a-z- ]*$/i)

export default {
  props: ['passenger', 'model'],
  data () {
    return {}
  },
  validations: {
    model: {
      first_name: { required, isNameValid },
      last_name: { required, isNameValid },
      card_no: { required, numeric, minLength: minLength(16), maxLength: maxLength(16), necNumberValidate }
    }
  },
  watch: {
  // For emitting validation status of this component to parent
    '$v.model': {
      handler () {
        this.$emit('validation-status', { trans_seq_num: this.passenger.trans_seq_num, validationStatus: !this.$v.$invalid })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="stylus">
</style>
