<template>
  <div class="card-container">
    <div class="journey-header-container">
      <q-icon :name="direction === 'Outbound' ? 'arrow_forward' : 'arrow_backward'" color="primary" size="2em" />
      <p class="text-h6 text-captalize">
        {{ direction }} -
      </p>
      <p>{{ formattedDate }}</p>
    </div>
    <div class="row justify-around">
      <div>
        <p class="text-weight-bold">
          {{ departureTime.slice(0, 5) }}
        </p>
        <p>
          {{ origin }}
        </p>
      </div>
      <q-icon name="arrow_forward" color="primary" size="1.5rem" />
      <div>
        <p class="text-weight-bold">
          {{ arrivalTime.slice(0, 5) }}
        </p>
        <p>
          {{ destination }}
        </p>
      </div>
    </div>
    <p class="text-caption">
      {{ formattedDuration }}, {{ numberOfChangesText }}
    </p>
  </div>
</template>

<script>
import date from 'utils/date-time'
const { toMediumDate } = date

export default {
  props: ['direction', 'date', 'departureTime', 'arrivalTime', 'origin', 'destination', 'duration', 'changes'],
  data () {
    return {}
  },
  computed: {
    formattedDate () {
      return toMediumDate(this.date)
    },
    formattedDuration () {
      const [hours, minutes] = this.duration.split(':')
      return `${Number(hours).toString()}h ${minutes}m`
    },
    numberOfChangesText () {
      const numberOfChanges = this.changes.length
      return `${numberOfChanges} Change${numberOfChanges > 1 || numberOfChanges === 0 ? 's' : ''}`
    }
  }
}
</script>
<style lang="stylus">

  .card-container
    display flex
    flex-direction column
    gap 2rem

  .journey-header-container
    display flex
    align-items center
    gap: 0.25rem
</style>
