<template>
  <q-page padding class="page-container">
    <p class="text-h6">
      Add Hold Luggage
    </p>
    <div class="info-container">
      <q-icon name="info" color="primary" size="1.5em" />
      <span class="info-text">
        Each member of your group is allocated one small carry-on item and one large luggage item at no extra cost.
        <br>You can add up to two additional items per passenger.
        <br>Any additional charges will be displayed during the selection process and reflect the total price for the journey.
      </span>
    </div>
    <q-separator />
    <div v-for="(passenger, index) in stash.luggageParams" :key="index" class="passenger-container">
      <p>Passenger {{ index + 1 }} - {{ passenger.label }}</p>
      <div class="luggage-selection">
        <p class="text-subtitle2">
          Additional Luggage (£5.00 per item)
        </p>
        <div class="number-selector">
          <q-btn
            round
            color="primary"
            icon="remove"
            size="sm"
            unelevated
            :disabled="passenger.additionalLuggage === 0"
            @click="updateLuggage(index, 'additionalLuggage', passenger.additionalLuggage - 1)"
          />
          <p>{{ passenger.additionalLuggage }}</p>
          <q-btn
            round
            color="primary"
            icon="add"
            size="sm"
            unelevated
            :disabled="passenger.additionalLuggage === 2"
            @click="updateLuggage(index, 'additionalLuggage', passenger.additionalLuggage + 1)"
          />
        </div>
      </div>
      <div class="luggage-selection">
        <p class="text-subtitle2">
          Bicycle Storage (£0.00 per item)
        </p>
        <div class="number-selector">
          <q-btn
            round
            color="primary"
            icon="remove"
            size="sm"
            unelevated
            :disabled="passenger.bicycleStorage === 0"
            @click="updateLuggage(index, 'bicycleStorage', passenger.bicycleStorage - 1)"
          />
          <p>{{ passenger.bicycleStorage }}</p>
          <q-btn
            round
            color="primary"
            icon="add"
            size="sm"
            unelevated
            :disabled="passenger.bicycleStorage === 1"
            @click="updateLuggage(index, 'bicycleStorage', passenger.bicycleStorage + 1)"
          />
        </div>
      </div>
      <q-separator />
    </div>
    <p class="text-h6 text-right">
      Total additional cost: {{ totalAdditionalCost }}
    </p>
    <q-separator />
    <m-cta-button
      label="Find Tickets"
      width="inherit"
      @ctaClick="submit"
    />
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { MCtaButton } from 'components/'

export default {
  components: { MCtaButton },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    disabled () {
      if (this.isOutbound) {
        if (this.stash.selected?.depart) return false
      }
      if (this.isReturn) {
        if (this.stash.selected?.return) return false
      }
      return true
    },
    totalAdditionalCost () {
      const luggageParams = this.stash.luggageParams
      const filteredLuggageParams = luggageParams.filter(param => param.additionalLuggage > 0)
      const totalCountOfLuggage = filteredLuggageParams.reduce((sum, param) => sum + param.additionalLuggage, 0)
      return `£${(5 * totalCountOfLuggage).toFixed(2)}`
    }
  },
  created () {
    const fareIds = this.stash.params.fare_ids

    let luggageParams = []

    const fareIdMap = {
      ADULT: 'Adult',
      CHILD: 'Child',
      CONCESSION: '60+/Disabled NEC Holder',
      U22: 'Under 22 NEC Holder',
      YPEC: 'Volunteer NEC Holder'
    }

    for (let fare of fareIds) {
      for (let i = 0; i < fare.count; i++) {
        luggageParams.push(
          {
            type: fare.fare_id,
            label: fareIdMap[fare.fare_id],
            additionalLuggage: 0,
            bicycleStorage: 0
          }
        )
      }
    }

    this.$store.dispatch('ondemand/stash', { luggageParams,
      // Clear any bicycle ssr's in case of navigating back to hold luggage
      params: {
        ...this.stash.params,
        ...(this.stash.params.ssr && { ssr: [
          ...this.stash.params.ssr.filter(ssr => ssr.ssr_type !== 'BICYCLE')
        ] })
      } })
  },
  methods: {
    updateLuggage (passengerIndex, luggageType, value) {
      let luggageParams = this.stash.luggageParams

      luggageParams[passengerIndex][luggageType] = value
      this.$store.dispatch('ondemand/stash', { luggageParams })
    },
    submit () {
      const luggageParams = this.stash.luggageParams
      const filteredLuggageParams = luggageParams.filter(param => param.bicycleStorage > 0)

      if (filteredLuggageParams.length) {
        let bicycleSsr = []

        filteredLuggageParams.forEach(luggageParams => {
          const existingIndex = bicycleSsr.findIndex(ssr => ssr.fare_line_id === luggageParams.type)

          if (existingIndex > -1) {
            bicycleSsr[existingIndex].ssr_count += luggageParams.bicycleStorage
          } else {
            bicycleSsr.push({
              fare_line_id: luggageParams.type,
              ssr_count: luggageParams.bicycleStorage,
              ssr_type: 'BICYCLE'
            })
          }
        })

        this.$store.dispatch('ondemand/stash', { params: {
          ...this.stash.params,
          ssr: [
            ...(this.stash.params.ssr || []),
            ...bicycleSsr
          ]
        } })
      }
      this.$router.push({ name: 'ondemand-coach-outbound' })
    }
  }
}
</script>
<style lang="stylus">
.page-container
  display flex
  flex-direction column
  gap 1rem

.info-container
 border-radius 16px
 border 1px solid var(--q-color-primary)
 padding 1rem
 display flex
 gap 0.5rem

.info-text
  color var(--q-color-primary)

.passenger-container
 display flex
 flex-direction column
 gap 1rem

.luggage-selection
  display flex
  gap 0.5rem
  justify-content space-between

.number-selector
  display flex
  gap 1rem
  align-items center

</style>
